import { createStore } from 'vuex'

export default createStore({
  state: {
    asideVisible: false,
    sidebarVisible: true,
    sidebarUnfoldable: false,
    theme: 'default',

    isLoggedIn: !!localStorage.getItem("token"),
    isLog : !!localStorage.getItem("token"),
    baseURL : 'https://hclstaging.animos.org/api/',
    // baseURL : 'http://localhost:4050/api/',
    token : localStorage.getItem("token"),
    user : JSON.parse(localStorage.getItem("user")),
    filters : {
      sourceIntervalType : 'range',
      sourceType : 'admission',
      date : null,
      startDate : null,
      endDate : null,
      outcome : null,
      zone : [],
      class : null,
      family : null,
      group : null,
      identity : null,
      caseno : null,
      scientific : null,
      stage : null,
      facility : null,
      placeOfRescue : [],
      placeOfRescueText : "",
      placeOfReleaseText : "",
      causeOfDisp : [],
      month : null,
      year : null,
    }
  },
  mutations: {
    toggleAside(state) {
      state.asideVisible = !state.asideVisible
      console.log("Toggle Aside : ");
      console.log(state)
    },
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
      console.log("Toggle Sidebar : ");
      console.log(state)
    },
    toggleTheme(state, payload) {
      state.theme = payload.value
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value;   
      console.log("Toggle Sidebar Visible : ");
      console.log(state)   
      console.log(payload) 
    },
    set (state, [variable, value]) {
      state[variable] = value
    },
    loginUser (state, payload) {
        state.isLoggedIn = true
        state.isLog = true
        state.token = payload.token;
        state.user = payload.user;
    },
    logoutUser (state) {
        state.isLoggedIn = false
        state.isLog = false
        state.token = null
        state.user = null
    },
    updateFilter(state, payload){
      state.filters = {
        sourceIntervalType : payload.sourceIntervalType,
        sourceType : payload.sourceype,
        date : payload.date,
        startDate : payload.startDate,
        endDate : payload.endDate,
        outcome : payload.outcome,
        zone : payload.zone,
        class : payload.class,
        family : payload.family,
        group : payload.group,
        identity : payload.identity,
        caseno : payload.caseno,
        scientific : payload.scientific,
        stage : payload.stage,
        facility : payload.facility,
        placeOfRescue : payload.placeOfRescue,
        placeOfReleaseText : payload.placeOfReleaseText,
        placeOfRescueText : payload.placeOfRescueText,
        causeOfDisp : payload.causeOfDisp,
        month : payload.month,
        year : payload.year,
      }
    }

  },
  actions: {},
  modules: {},
})
