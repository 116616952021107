import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Notifications from '@kyvg/vue3-notification'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

import CoreuiVue from '@coreui/vue-pro'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsCallout from '@/components/DocsCallout'
import DocsExample from '@/components/DocsExample'


router.beforeEach((to, from, next) => {
    // check if the route requires authentication and user is not logged in
    if (to.matched.some(route => route.meta.requiresAuth) && !store.state.isLoggedIn) {
      // redirect to login page
      console.log("Reditect")
      next({ name: 'Login' })
      return
    }
  
    // if logged in redirect to dashboard
    if (to.path === '/login' && store.state.isLoggedIn) {
      next({ name: '' })
      return
    }
  
      
    next()
  })



const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(Notifications)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsCallout', DocsCallout)
app.component('DocsExample', DocsExample)
app.component('v-select', vSelect)

app.mount('#app')
