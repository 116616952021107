<template>
  <notifications group="user-info" position="bottom center"/>
  <notifications group="notification" position="top right"/>  
  <router-view />
</template>
<script>
import { watch } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'App',
  watch: {
      '$route' (to, from) {
        document.title = to.meta.title || 'ABC Harit App | HCL Foundation'
      }
    },
}
</script>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>
