<template>
  <CFooter>
    <div>
      Copyright
          <span class="ml-1">&copy; {{new Date().getFullYear()}}. All rights reserved | </span> <small>Version : 1.0.0 (beta)</small>
    </div>
    <div class="ms-auto">
      <span class="mr-1">Developed by </span>
      <a href="http://strofes.com/" target="_blank">Strofes Technologies</a> for 
      <a href="https://www.hclfoundation.org" target="_blank">HCL Foundation</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
