<template>
  <CHeader position="sticky" class="bg-primary mb-4">
    <CContainer fluid>
      <!-- <CHeaderToggler
        class="px-md-0 me-md-3 d-md-none"
        @click="$store.commit('toggleSidebar')"
      >
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler> -->
      <CHeaderBrand class="mx-auto d-lg-none" to="/">
        
      </CHeaderBrand>
      <CNavbar expand="lg" class="text-light">
        <CContainer fluid>
          <CNavbarToggler aria-label="Toggle navigation" aria-expanded={visible} @click="visible = !visible"/>
          <CCollapse class="navbar-collapse" :visible="visible">
            <CNavbarBrand href="/">
              <img class="d-block" :src="logo" alt="HCL Foundation"  height="48"/>
            </CNavbarBrand>
            <CNavbarNav class="me-auto mb-2 mb-lg-0 text-white">
              <CNavItem>
                <CNavLink href="/dashboard" active class="text-white">
                  Dashboard
                </CNavLink>
              </CNavItem>

              <!-- <CNavItem>
                <CNavLink href="/analytics" active class="text-white">
                  Analytics
                </CNavLink>
              </CNavItem> -->

              <CDropdown variant="nav-item" :popper="false">
                <CDropdownToggle class="text-white">Case Management</CDropdownToggle>
                <CDropdownMenu>
                  <CDropdownItem href="/completed-cases">Completed Cases</CDropdownItem>
                  <CDropdownItem href="/incomplete-cases">Incomplete Cases</CDropdownItem>
                  <CDropdownDivider />
                  <CDropdownItem href="/add-case">Add a new Case</CDropdownItem>
                </CDropdownMenu>
              </CDropdown>

              <CDropdown variant="nav-item" :popper="false">
                <CDropdownToggle class="text-white">Administration</CDropdownToggle>
                <CDropdownMenu>
                  <CDropdownItem href="/users">Manage Users</CDropdownItem>
                  <CDropdownItem href="/organizations">Manage Organizations</CDropdownItem>
                  <CDropdownDivider />
                  <CDropdownItem href="/species">Manage Species</CDropdownItem>
                </CDropdownMenu>
              </CDropdown>

              <CNavItem>
                <CNavLink href="/about" active class="text-white">
                  About ANIMOS
                </CNavLink>
              </CNavItem>
              
              
            </CNavbarNav>
          </CCollapse>
        </CContainer>
      </CNavbar>
      <!-- <CForm class="d-flex">
        <CInputGroup>
          <CInputGroupText id="search-addon" class="bg-light border-0 px-1">
            <CIcon
              icon="cil-search"
              size="lg"
              class="my-1 mx-2 text-disabled"
            />
          </CInputGroupText>
          <CFormInput
            placeholder="Search..."
            ariaLabel="Search"
            ariaDescribedby="search-addon"
            class="bg-light border-0"
          />
        </CInputGroup>
      </CForm> -->
      <!-- <CHeaderNav class="ms-auto">
        <AppHeaderDropdownNotif />
      </CHeaderNav> -->
      <CHeaderNav class="ms-3 me-4">
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
  </CHeader>
</template>

<script>
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import AppHeaderDropdownMssgs from './AppHeaderDropdownMssgs'
import AppHeaderDropdownNotif from './AppHeaderDropdownNotif'
import AppHeaderDropdownTasks from './AppHeaderDropdownTasks'
import logo from '@/assets/images/hclf-logo-new.png';

export default {
  name: 'AppHeader',
  components: {
    AppHeaderDropdownAccnt,
    AppHeaderDropdownMssgs,
    AppHeaderDropdownNotif,
    AppHeaderDropdownTasks,
  },
  setup() {
    return {
      logo,
      visible : true
    }
  },
}
</script>
